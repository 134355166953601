import React, { useEffect, useState } from "react";
import { get } from "lodash";

import * as Templates from "../../templates";

const templatesMap = {
	Abstinence: "Abstinence",
	belltower: "Belltower",
	book: "Book",
	broadcast: "Broadcast",
	Calendar: "Calendar",
	cathedral: "Cathedral",
	chapel: "Chapel",
	church: "Church",
	city: "City",
	CityObject: "CityObject",
	Hermitage: "Hermitage",
	Holiday: "Holiday",
	Ikon: "Ikon",
	Media: "Media",
	media_article: "Article",
	Monastery: "Monastery",
	Museum: "Museum",
	news: "News",
	Radio: "Radio",
	Saint: "Saint",
	SaintBook: "SaintBook",
	SerafimProject: "SerafimProject",
	Shrine: "Shrine",
	Temple: "Temple",
	page: "TextPages",
	tour: "Tour",
	TV: "TV",
	Video: "Video",
	virtual_tour: "VirtualTour",
	well: "Well",
};

export default function PreviewPage() {
	const [data, setData] = useState({
		slug: "",
		type: "",
	});

	function getData({ data }) {
		const slug = get(data, "slug", "");
		const type = get(data, "type", "");

		if (slug && type) {
			setData({
				slug,
				type,
			});
		}
	}

	useEffect(() => {
		if (typeof window !== "undefined" && window.opener) {
			window.opener.postMessage({ previewLoaded: true }, "*");

			window.addEventListener("message", getData);
		} else if (typeof window !== "undefined") {
			location.replace("/");
		}

		return () => {
			if (typeof window !== "undefined") {
				window.removeEventListener("message", getData);
			}
		};
	}, []);

	return React.createElement(
		get(Templates, `[${templatesMap[data.type] || "DefaultPreview"}]`, null),
		{
			previewData: data,
		},
	);
}
